import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { EventListPage, EventDetailPage } from './pages';
import Layout from './layout/layout';

const theme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 718
		}
	},
	palette: {
		primary: {
			main: '#127652',
			light: '#f1f1f1',
			contrastText: '#ffffff'
		},
		secondary: {
			light: '#0066ff',
			main: '#0044ff',
			contrastText: '#ffcc00'
		},
		contrastThreshold: 3,
		tonalOffset: 0.2
	},
	typography: {
		h6: {
			fontSize: '1em',
			fontWeight: 'bold'
		}
	}
});

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Layout>
				<Route component={EventListPage} path="/:type" exact />
				<Route component={EventDetailPage} path="/:type/:id" />
				<Route path="/">
					<Redirect to="/ing" />
				</Route>
			</Layout>
		</ThemeProvider>
	);
};

export default App;
