import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useControlLocation = () => {
	const [depth1, setDepth1] = useState('');
	const [depth2, setDepth2] = useState('');
	const { pathname } = useLocation();
	const history = useHistory();
	const historyPush = (path = '/ing') => {
		history.push(path);
	};
	useEffect(() => {
		const splitedPathname = pathname.split('/');
		if (splitedPathname[1].length > 0) {
			setDepth1(splitedPathname[1]);
			if (splitedPathname.length > 2) {
				setDepth2(splitedPathname[2]);
			} else {
				setDepth2('');
			}
		} else {
			setDepth1('ing');
			setDepth2('');
			history.push('/ing');
		}
	}, [pathname, history]);

	return { depth1, depth2, historyPush };
};

export default useControlLocation;
