import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	contentBox: {
		paddingLeft: theme.spacing[5],
		'& img': {
			maxWidth: 640
		}
	},
	titleBox: {
		width: '100%',
		minHeight: '60px',
		borderTop: '1px solid #cccccc',
		borderBottom: '1px solid #cccccc'
	}
}));

const EventDetail = ({ event, date }) => {
	const classes = useStyles();

	return (
		<Box component="section" display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="background.paper">
			<Box className={classes.titleBox} display="flex" flexDirection="column" alignItems="start" justifyContent="center" pl={2}>
				<Typography>{event.TTL}</Typography>
				<Typography variant="body2" color="textSecondary">
					{date}
				</Typography>
			</Box>
			<div className={classes.contentBox} dangerouslySetInnerHTML={{ __html: event.MOB_CNN }} />
		</Box>
	);
};

export default EventDetail;
