import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetailAction } from 'modules/event';
import EventDetail from 'components/eventDetail/eventDetail';

const EventDetailContainer = () => {
	const { event } = useSelector(({ eventReducer }) => eventReducer);
	const { type, id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(eventDetailAction({ type, id }));
	}, [dispatch, type, id]);

	const getEventDate = (data) => {
		const getDayString = (string) => {
			return string.split(' ')[0].replace(/-/g, '.');
		};
		return `${getDayString(data.DSY_BGN_DTM)} - ${getDayString(data.DSY_CL_DTM)}`;
	};

	if (event.data == null) return <div />;
	return <EventDetail event={event.data[0].Items[0]} date={getEventDate(event.data[0].Items[0])} />;
};

export default EventDetailContainer;
