import React from 'react';
import { Box, Typography, ButtonGroup, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	navItem: {
		textDecoration: 'none',
		height: '44px',
		width: '100%',
		color: theme.palette.grey[500],
		display: 'inline-grid',
		alignItems: 'center',
		'&:hover': {
			opacity: 0.8
		}
	},
	active: {
		display: 'grid',
		height: '44px',
		width: '100%',
		lineHeight: '44px',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		backgroundColor: theme.palette.background.paper
	},
	buttons: {
		padding: 7
	},
	button: {
		padding: 0,
		'.MuiButton-label': {
			display: 'block',
			margin: 0
		}
	}
}));

const links = [
	{
		name: '진행중인 이벤트',
		path: '/ing',
		slug: 'ing'
	},
	{
		name: '종료된 이벤트',
		path: '/end',
		slug: 'end'
	},
	{
		name: '당첨자 발표',
		path: '/winner',
		slug: 'winner'
	}
];
const Nav = () => {
	const classes = useStyles();
	return (
		<Box display="flex" bgcolor="primary.light" justifyContent="center" height={'60px'}>
			<ButtonGroup className={classes.buttons} fullWidth>
				{links.map((link) => (
					<Button key={link.slug} className={classes.button}>
						<NavLink className={classes.navItem} to={link.path} activeClassName={classes.active}>
							<Typography variant="h6">{link.name}</Typography>
						</NavLink>
					</Button>
				))}
			</ButtonGroup>
		</Box>
	);
};

export default Nav;
