import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridList, GridListTile } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper
	},
	gridList: {
		cursor: 'pointer',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		maxWidth: 718,
		padding: 0
	}
}));

const EventList = ({ type, images = [], onListItemClick }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<GridList cellHeight="auto" spacing={10} cols={1}>
				{/* component 분리는 하게되면 material-ui에서 인식을 다르게 함 */}
				{images.map((item) => (
					<GridListTile key={item.BBM_ID} className={{ root: classes.gridList }} onClick={() => onListItemClick(type, item.BBM_ID)}>
						<img src={item.WEB_IMAGE_PATH + item.DISP_FL_NM} alt={item.TTL} />
					</GridListTile>
				))}
			</GridList>
		</div>
	);
};

export default EventList;
