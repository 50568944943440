import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import eventReducer, { eventSaga } from './event';
import eventsReducer, { eventsSaga } from './events';

const rootReducer = combineReducers({
	eventsReducer,
	eventReducer
});

export function* rootSaga() {
	yield all([eventsSaga(), eventSaga()]);
}

export default rootReducer;
