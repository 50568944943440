import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createRequestActionType, createPromiseSaga, reducerUtils, handleDefaultActions } from '../lib/request';
import * as eventAPI from 'api/event';

// 1.Creact Action Type
const [EVENT_LIST, EVENT_LIST_SUCCESS, EVENT_LIST_FAILURE] = createRequestActionType('event/EVENT_LIST');

// 2.Create Action
export const eventListAction = createAction(EVENT_LIST);

// 3.Create Saga function
const getEventList = createPromiseSaga(EVENT_LIST, eventAPI.getEventList);

export function* eventsSaga() {
	yield takeLatest(EVENT_LIST, getEventList);
}

// 4.Create state
const initState = {
	events: reducerUtils.initial()
};

// 5.Create Action handling
const eventsReducer = (state = initState, action) => {
	switch (action.type) {
		case EVENT_LIST:
		case EVENT_LIST_SUCCESS:
		case EVENT_LIST_FAILURE:
			return handleDefaultActions(EVENT_LIST, 'events', false)(state, action);
		default:
			return state;
	}
};

export default eventsReducer;
