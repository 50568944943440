import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import useControlLocation from 'hooks/useControlLocation';
import Logo from 'components/common/logo';

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper
	})
}));

const Header = ({ depth1, depth2 }) => {
	const classes = useStyles();
	const { historyPush } = useControlLocation();
	return (
		<Box className={classes.root} display="flex" minHeight="60px" justifyContent="start" flexWrap="wrap" alignItems="center" pl={depth2 ? 0 : 1}>
			{!!depth2 && (
				<IconButton color="inherit" onClick={() => historyPush(`/${depth1}`)}>
					<ArrowBackIosIcon />
				</IconButton>
			)}
			<Logo />
		</Box>
	);
};

export default Header;
