import { call, put } from 'redux-saga/effects';

export const createRequestActionType = (type) => {
	return [type, `${type}_SUCCESS`, `${type}_FAILURE`];
};

export const createPromiseSaga = (type, promiseCreator) => {
	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
	return function* (action) {
		try {
			const payload = yield call(promiseCreator, action.payload);
			yield put({ type: SUCCESS, payload });
		} catch (err) {
			yield put({ type: FAILURE, error: true, payload: err });
		}
	};
};

// export const createPromiseSagaById = (type, promiseCreator) => {
// 	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
// 	return function* (action) {
// 		const id = action.meta;
// 		try {
// 			const payload = yield call(promiseCreator, action.payload);
// 			yield put({ type: SUCCESS, payload, meta: id });
// 		} catch (err) {
// 			yield put({ type: FAILURE, error: err, meta: id });
// 		}
// 	};
// };

export const reducerUtils = {
	initial: (initialData = null) => ({
		loading: false,
		data: initialData,
		error: null
	}),
	loading: (prevState = null) => ({
		loading: true,
		data: prevState,
		error: null
	}),
	success: (payload) => ({
		loading: false,
		data: payload,
		error: null
	}),
	error: (error) => ({
		loading: false,
		data: null,
		error: error
	})
};

export const handleDefaultActions = (type, key, keepData = false) => {
	const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
	return (state, action) => {
		switch (action.type) {
			case type:
				return {
					...state,
					[key]: reducerUtils.loading(keepData ? state[key].data : null)
				};
			case SUCCESS:
				return {
					...state,
					[key]: reducerUtils.success(action.payload)
				};
			case ERROR:
				return {
					...state,
					[key]: reducerUtils.error(action.payload)
				};
			default:
				return state;
		}
	};
};

// export const handleAsyncActionsById = (type, key, keepData = false) => {
// 	const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
// 	return (state, action) => {
// 		const id = action.meta;
// 		switch (action.type) {
// 			case type:
// 				return {
// 					...state,
// 					[key]: {
// 						...state[key],
// 						[id]: reducerUtils.loading(
// 							// state[key][id]가 만들어져있지 않을 수도 있으니까 유효성을 먼저 검사 후 data 조회
// 							keepData ? state[key][id] && state[key][id].data : null
// 						)
// 					}
// 				};
// 			case SUCCESS:
// 				return {
// 					...state,
// 					[key]: {
// 						...state[key],
// 						[id]: reducerUtils.success(action.payload)
// 					}
// 				};
// 			case ERROR:
// 				return {
// 					...state,
// 					[key]: {
// 						...state[key],
// 						[id]: reducerUtils.error(action.payload)
// 					}
// 				};
// 			default:
// 				return state;
// 		}
// 	};
// };
