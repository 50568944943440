import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventListAction } from 'modules/events';
import EventList from 'components/eventList/eventList';
import useControlLocation from 'hooks/useControlLocation';

const EventListContainer = () => {
	const { events } = useSelector(({ eventsReducer }) => eventsReducer);
	const { historyPush, depth1 } = useControlLocation();
	const dispatch = useDispatch();

	const handleListItemClick = (type, id) => {
		historyPush(`${type}/${id}`);
	};

	useEffect(() => {
		dispatch(eventListAction(depth1.toUpperCase()));
	}, [dispatch, depth1]);

	return <>{events.data !== null && <EventList type={depth1} images={events.data.Items} onListItemClick={handleListItemClick} />}</>;
};

export default EventListContainer;
