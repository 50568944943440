import React from 'react';
import { Container, Box } from '@material-ui/core';
import { Header, Footer, Nav } from 'layout/components';
import useControlLocation from 'hooks/useControlLocation';

const Layout = ({ children }) => {
	const { depth1, depth2 } = useControlLocation();
	return (
		<Container maxWidth="md">
			<Header depth1={depth1} depth2={depth2} />
			{!depth2 && <Nav />}
			<Box>{children}</Box>
			<Footer />
		</Container>
	);
};

export default Layout;
