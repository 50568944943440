import React from 'react';
import { Box } from '@material-ui/core';
import logo from 'asset/logo_header.png';

const Logo = () => {
	return (
		<Box p={1}>
			<img style={{ width: 64, height: 21 }} src={logo} alt="logo" />
		</Box>
	);
};

export default Logo;
