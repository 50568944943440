import axios from 'axios';

export const getEventList = async (type) => {
	const apiResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/events/${type}`);
	return apiResponse.data.data;
};

export const getEventDetail = async ({type, id}) => {
	const apiResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/eventDetail/${type}/${id}`);
	return apiResponse.data.data;
};
