import React from 'react';
import EventList from 'containers/eventList/eventListContainer';

const EventListPage = () => {
	return (
		<>
			<EventList />
		</>
	);
};

export default EventListPage;
