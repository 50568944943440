import React from 'react';
import { Box } from '@material-ui/core';
import logo from 'asset/logo_footer.png';

const Footer = () => {
	return (
		<Box height="100px" display="flex" alignItems="center" justifyContent="center" borderTop={1} bgcolor="background.paper">
			© {new Date().getFullYear()} <img style={{ width: 67, height: 23, marginLeft: 5 }} src={logo} alt="logo" />
		</Box>
	);
};

export default Footer;
